/** @jsxImportSource @emotion/react */
import React from 'react';
import Tour from 'reactour';

import { css, keyframes } from '@emotion/react';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  TouchApp as TouchAppIcon,
} from '@mui/icons-material';
import { IconButton, Paper, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import type { TourGuideProps } from './TourGuide.types';

const TourGuideRoot = styled(Tour)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    maxWidth: '300px !important', // Mobile helper width fix.
  },
  '& div[data-tour-elem="controls"]': {
    justifyContent: 'center',
  },
}));

const TourGuideSwipeIconWrapper = styled((props) => (
  <Paper variant="outlined" {...props} />
))(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2.5),
  display: 'flex',
  alignCenter: 'center',
  justifyContent: 'center',
}));

const TourGuideSwipeIconVerticalWrapper = styled('span')({
  transform: 'rotate(-90deg)',
});

const TourGuideSwipeAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(-20deg);
  }
  70% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(0deg);
  }
`;

const TourGuide: React.FC<TourGuideProps> = ({
  onPointHotspotStep,
  tourType = 'aerial',
  ...other
}) => {
  const theme = useTheme();

  const TourGuideTouchAppIconCSS = css`
    transform-origin: 30% 100%;
    animation-name: ${TourGuideSwipeAnimation};
    animation-timing-function: ${theme.transitions.easing.easeInOut};
    animation-duration: ${theme.transitions.duration.standard * 10}ms;
    animation-iteration-count: infinite;
  `;

  const renderContent = (content: React.ReactNode) => (
    <Typography variant="body2">{content}</Typography>
  );

  const getSteps = () => {
    switch (tourType) {
      case 'unit':
        return [
          {
            selector: '.unitNav',
            content: renderContent(
              'Show features by selecting on different types of filter. Menu might not be completely visible while in mobile view, scroll to see more.'
            ),
          },
          {
            selector: '#unitCanvasWrapper',
            content: (
              <>
                {renderContent(
                  'Drag or swipe horizontally to rotate the layout view.'
                )}
                <TourGuideSwipeIconWrapper>
                  <TouchAppIcon
                    color="action"
                    fontSize="large"
                    css={TourGuideTouchAppIconCSS}
                  />
                </TourGuideSwipeIconWrapper>
              </>
            ),
          },
          {
            selector: '.pointHotspot',
            action: onPointHotspotStep,
            content: renderContent(
              'Click or press to view the description of the feature.'
            ),
          },
          {
            selector: '#exitToAerialViewButton',
            content: renderContent(
              'Click on this button to return to the aerial view.'
            ),
          },
          {
            selector: '.helpButton',
            content: renderContent(
              'Click on the Help button to open up this tour again.'
            ),
          },
        ];
      case 'layer':
        return [
          {
            selector: '.layerNav',
            resizeObservables: ['.layerNavObservable'],
            content: renderContent('Change layer view through this menu.'),
          },
          {
            selector: '#layerCanvasWrapper',
            content: (
              <>
                {renderContent(
                  'Drag or swipe vertically to change the layer view.'
                )}
                <TourGuideSwipeIconWrapper>
                  <TourGuideSwipeIconVerticalWrapper>
                    <TouchAppIcon
                      color="action"
                      fontSize="large"
                      css={TourGuideTouchAppIconCSS}
                    />
                  </TourGuideSwipeIconVerticalWrapper>
                </TourGuideSwipeIconWrapper>
              </>
            ),
          },
          {
            selector: '.helpButton',
            content: renderContent(
              'Click on the Help button to open up this tour again.'
            ),
          },
        ];
      case 'aerial':
      default:
        return [
          {
            selector: '.arrowButton',
            resizeObservables: ['.arrowButtonObservable'],
            content: renderContent(
              'Change aerial viewpoint by clicking left or right button.'
            ),
          },
          {
            selector: '.virtualTourIconButton',
            content: renderContent(
              'Click on any of these icons to open up virtual tour based on the location.'
            ),
          },
          {
            selector: '#enterUnitViewButton',
            content: renderContent(
              'Click on this icon to enter unit layout view.'
            ),
          },
          {
            selector: '.inspectToolbar',
            resizeObservables: ['.inspectToolbar'],
            content: renderContent(
              'Click on this to enable zoom in / out for the image. Exit preview mode to rotate canvas.'
            ),
          },
          {
            selector: '.helpButton',
            content: renderContent(
              'Click on the Help button to open up this tour again.'
            ),
          },
        ];
    }
  };

  return (
    <TourGuideRoot
      {...other}
      startAt={0}
      updateDelay={2000}
      steps={getSteps()}
      accentColor={theme.palette.primary.light}
      rounded={theme.shape.borderRadius as number}
      prevButton={
        <IconButton component="div" tabIndex={-1} size="small">
          <ChevronLeftIcon />
        </IconButton>
      }
      nextButton={
        <IconButton component="div" tabIndex={-1} size="small">
          <ChevronRightIcon />
        </IconButton>
      }
    />
  );
};

export default TourGuide;
