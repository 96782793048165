import { ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';

const DrawerLandingAsideListSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.MuiListSubheader-gutters': {
    padding: theme.spacing(2.5, 2),
  },
}));

export default DrawerLandingAsideListSubheader;
