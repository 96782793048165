import { useEffect, useRef } from 'react';

function useSavedCallback(cb: any) {
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = cb;
  });

  return savedCallback;
}

export default useSavedCallback;
