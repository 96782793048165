// Import assets for panonara view.
import './assets/js/pano2vr_player';

import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';

import { BackdropLoader } from './components';
import { TourProvider } from './contexts';
import { GardenRoutes } from './routes';
import theme from './styles';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Suspense fallback={<BackdropLoader open />}>
            <TourProvider>
              <GardenRoutes />
            </TourProvider>
          </Suspense>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
