import qs from 'query-string';
import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '../';
import { ORIENTATION_SEQUENCES } from '../../utils';

const GardenVirtualTourPage = lazy(
  () => import('../../pages/GardenVirtualTour')
);
const UnitType1VirtualTourPage = lazy(
  () => import('../../pages/UnitType1VirtualTour')
);
const GardenPage = lazy(() => import('../../pages/Garden'));
const UnitPage = lazy(() => import('../../pages/Unit'));

const Garden: React.FC = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.GardenVirtualTour}
      component={GardenVirtualTourPage}
    />
    <Route
      exact
      path={ROUTES.UnitType1VirtualTour}
      component={UnitType1VirtualTourPage}
    />
    <Route path={ROUTES.UnitType1} component={UnitPage} />
    <Route exact path={ROUTES.Garden} component={GardenPage} />
    <Redirect
      to={{
        pathname: ROUTES.Garden,
        search: `?${qs.stringify({
          orientation: ORIENTATION_SEQUENCES[0],
        })}`,
      }}
    />
  </Switch>
);

export default Garden;
