import qs from 'query-string';
import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '../';

const UnitFloorPlanPage = lazy(() => import('../../pages/UnitFloorPlan'));
const UnitType1Page = lazy(() => import('../../pages/UnitType1'));

const Unit: React.FC = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.UnitType1FloorPlan}
      component={UnitFloorPlanPage}
    />
    <Route exact path={ROUTES.UnitType1} component={UnitType1Page} />
    <Redirect
      to={{
        pathname: ROUTES.UnitType1,
        search: `?${qs.stringify({ filter: 'exterior' })}`,
      }}
    />
  </Switch>
);

export default Unit;
