import qs from 'query-string';
import { useLocation } from 'react-router-dom';

const useQueryParam = (name: string, isArray?: boolean) => {
  const location = useLocation();
  const query = qs.parse(location.search);
  const value = query[name] ?? '';
  if (isArray) {
    return Array.isArray(value) ? value : [value];
  }
  return value;
};

export default useQueryParam;
