import { useRef } from 'react';

import { useDrag } from '@use-gesture/react';

function useDragThresholdDeltaX(
  onDragLeft: () => void,
  onDragRight: () => void,
  threshold = 20
) {
  const deltaThreshold = useRef(0);
  return useDrag(({ delta: [deltaX] }) => {
    deltaThreshold.current += deltaX;
    if (Math.abs(deltaThreshold.current) > threshold) {
      if (deltaX < 0) {
        onDragLeft();
      } else {
        onDragRight();
      }
      deltaThreshold.current = 0;
    }
  });
}

export default useDragThresholdDeltaX;
