export { default as GardenRoutes } from './Garden';
export * from './Garden';

export { default as UnitRoutes } from './Unit';
export * from './Unit';

export const ROUTES = {
  Garden: '/',
  GardenVirtualTour: '/virtual-tour',
  UnitType1: '/type-1',
  UnitType1FloorPlan: '/type-1/floor-plan',
  UnitType1VirtualTour: '/type-1/virtual-tour',
};
