import { useEffect, useState } from 'react';

import type {
  UseSequencedImagesImportProps,
  UseSequencedImagesImportOptions,
} from './useSequencedImagesImport.types';

const useSequencedImagesImport = (
  imgSrc: (i: number) => string,
  numberOfFrames: number,
  options: UseSequencedImagesImportOptions = {}
): UseSequencedImagesImportProps => {
  const [error, setError] = useState<Error | undefined>();
  const [imgSrcs, setImgSrcs] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);

  const { onCompleted, onError } = options;
  useEffect(() => {
    setLoading(true);
    const importAssets = async () => {
      const promises = [];
      for (let i = 1; i <= numberOfFrames; i++) {
        promises.push(import(`../../assets/images/${imgSrc(i)}`));
      }
      try {
        const _imgSrcs = (await Promise.all(promises)).map(
          (img) => img.default // Use default src.
        );
        onCompleted?.(_imgSrcs);
        setImgSrcs(_imgSrcs);
      } catch (err: any) {
        onError?.(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    importAssets();
  }, [imgSrc, numberOfFrames, onCompleted, onError, setLoading]);

  return { error, imgSrcs, loading };
};

export default useSequencedImagesImport;
