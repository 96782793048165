import { useState } from 'react';

import type { UseContrainedNumberProps } from './useConstraintedNumber.types';

const useConstraintedNumber = (
  initial: number,
  min = 0,
  max = Infinity,
  enableLoop?: boolean
): [number, UseContrainedNumberProps] => {
  const [current, setCurrent] = useState(initial);

  const constraint = (prevCurrent: number, newCurrent: number) => {
    if (newCurrent < min) {
      if (enableLoop) {
        return max;
      }
      return prevCurrent;
    }
    if (newCurrent > max) {
      if (enableLoop) {
        return 0;
      }
      return prevCurrent;
    }
    return newCurrent;
  };

  const increment = (i = 1) => {
    setCurrent((prevCurrent) => constraint(prevCurrent, prevCurrent + i));
  };

  const decrement = (i = 1) => {
    setCurrent((prevCurrent) => constraint(prevCurrent, prevCurrent - i));
  };

  return [current, { increment, decrement }];
};

export default useConstraintedNumber;
